import * as Cookies from 'js-cookie';
import log from 'loglevel';

const STORAGE_EVENT_KEY = 'storage';

/**
 * Storage pattern inspired by Mathias Bynens
 * https://mathiasbynens.be/notes/localstorage-pattern
 */

function isLocalStorageAvailable(): boolean {
  try {
    if (!__isBrowser__) {
      throw new Error('localStorage: not available on the server');
    }
    const timeInMilliseconds = String(new Date().getTime());
    const storage = window.localStorage;
    storage.setItem(timeInMilliseconds, timeInMilliseconds);

    const fail = storage.getItem(timeInMilliseconds) !== timeInMilliseconds;
    storage.removeItem(timeInMilliseconds);
    if (fail) {
      return false;
    }
    return true;
  } catch (exception) {
    log.debug('localStorage: feature not available');
    return false;
  }
}

const removeItem = (key: string): boolean => {
  if (!isLocalStorageAvailable()) {
    const cookieStorage = Cookies.getJSON('otovo-storage') || {};
    const cookieExpiryStorage = Cookies.getJSON('otovo-storage-expiry') || {};
    delete cookieExpiryStorage[key];
    delete cookieStorage[key];
    Cookies.set('otovo-storage', cookieStorage);
    Cookies.set('otovo-storage-expiry', cookieExpiryStorage);
    return true;
  }
  window.localStorage.removeItem(key);
  window.localStorage.removeItem(`${key}-expiry`);

  window.dispatchEvent(new Event(STORAGE_EVENT_KEY));
  return true;
};

const setItem = (
  key: string,
  value: any | Array<string>,
  expiry?: number,
): boolean => {
  if (!isLocalStorageAvailable()) {
    const cookieExpiryStorage = Cookies.getJSON('otovo-storage-expiry') || {};
    const cookieStorage = Cookies.getJSON('otovo-storage') || {};
    cookieStorage[key] = JSON.stringify(value);
    if (typeof expiry === 'number') {
      cookieExpiryStorage[key] = JSON.stringify(expiry);
    }
    Cookies.set('otovo-storage', cookieStorage);
    Cookies.set('otovo-storage-expiry', cookieExpiryStorage);
    return true;
  }
  window.localStorage.setItem(key, JSON.stringify(value));
  if (expiry) {
    window.localStorage.setItem(`${key}-expiry`, JSON.stringify(expiry));
  }
  window.dispatchEvent(new Event(STORAGE_EVENT_KEY));
  return true;
};

const getItem = <T>(key: string): T | null => {
  if (!isLocalStorageAvailable()) {
    const cookieStorage = Cookies.getJSON('otovo-storage') || {};
    const cookieExpiryStorage = Cookies.getJSON('otovo-storage-expiry') || {};
    if (cookieExpiryStorage[key]) {
      const expiryDate = JSON.parse(cookieExpiryStorage[key]);
      if (expiryDate < Date.now()) {
        removeItem(key);
        return null;
      }
    }
    if (cookieStorage[key]) {
      return JSON.parse(cookieStorage[key]);
    }
    return null;
  }
  const expiry = window.localStorage.getItem(`${key}-expiry`);
  const stored = window.localStorage.getItem(key);
  if (stored) {
    if (expiry && JSON.parse(expiry) < Date.now()) {
      removeItem(key);
      return null;
    }
    return JSON.parse(stored);
  }
  return null;
};

export default { isLocalStorageAvailable, getItem, setItem, removeItem };
