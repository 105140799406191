/* eslint-disable no-console */

/**
 * https://github.com/otovo/web/pull/1072
 * This is a workaround for a Chrome browser extension bug.
 * We believe the extension to be Google Translate.
 * See PR above for more details.
 */
const workaroundBrowserExtensions = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (typeof console !== 'undefined') {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (typeof console !== 'undefined') {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

export default workaroundBrowserExtensions;
