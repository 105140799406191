import Script from 'next/script';
import { useEffect, useState } from 'react';
import {
  generateGoogleTagManagerScriptBlock,
  generateWindowDatalayerScript,
} from './generateGoogleTagManagerScriptBlocks';
import { handleCookieKey } from './cookieUtils';
import { getOneTrustInitialSnippet } from './oneTrust';

type Props = {
  googleTagManagerId: string;
  query: string;
  cookieKey?: string;
};
/*
  This component configures Google Tag Manager with OneTrust.

  The component delays the load of Google Tag Manager until OneTrust is ready.
  However, we want the datalayer to exist immediately. The component handles
  that by setting upthe datalayer queue on server side render, waits for the
  OneTrust snippet on the client, and then loads the Google Tag Manager script.
*/
const GoogleTagManagerAndOneTrustScripts = ({
  googleTagManagerId,
  query,
  cookieKey,
}: Props) => {
  const [oneTrustInitialSnippet, setOneTrustInitialSnippet] = useState('');

  useEffect(() => {
    (async () => {
      handleCookieKey(cookieKey);
      setOneTrustInitialSnippet(await getOneTrustInitialSnippet());
    })();
  }, [cookieKey]);

  return (
    <>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
               ${generateWindowDatalayerScript({
                 query,
               })}`,
        }}
      />
      {oneTrustInitialSnippet && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `${oneTrustInitialSnippet}
               ${generateGoogleTagManagerScriptBlock({
                 googleTagManagerId,
               })}`,
          }}
        />
      )}
    </>
  );
};

export default GoogleTagManagerAndOneTrustScripts;
