/**
 * Utility for working with variables of a type that are possibly wrapped in an
 * Array, for example string | Array<string>.
 *
 * The query params in Next.js' router has such a type. This function returns
 * the first element of the array, or just the element if it's type is not an
 * array.
 *
 * @param element Util
 * @returns the first element of the array, or the element if it's not an array
 */

function getFirstFromArray<T>(element: Array<T> | T) {
  return Array.isArray(element) ? element[0] : element;
}

export default getFirstFromArray;
