import { Router } from 'next/router';
import { useEffect } from 'react';
import { initAnalytics, logPageView } from '../lib/analytics/analytics';

type Args = {
  path: string;
  isGTMEnabled: boolean;
};

export function useAnalytics({ path, isGTMEnabled }: Args) {
  initAnalytics({
    GOOGLE_TAG_MANAGER_ENABLED: isGTMEnabled,
  });
  useEffect(() => {
    logPageView(path); // Log initial page view
    // ^ this assumes initAnalytics is completed which won't be the case for the iframe.
    // However, this is actually a feature right now (prototype)
    const handleRouteChange = (url: string) => {
      logPageView(url);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
    // Run the useEffect code once, and once only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
