function decodeUint8ArrayToString(uint8Array: Uint8Array) {
  const newUint8Array = new Uint8Array(uint8Array);
  // @ts-expect-error - TS throws downlevelIteration error
  return String.fromCharCode(...newUint8Array);
}

function encodeBase64ToBase64Url(base64: string) {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function encodeUint8ArrayToBase64(uint8Array: Uint8Array) {
  return btoa(decodeUint8ArrayToString(uint8Array));
}

function encodeUint8ArrayToBase64Url(uint8Array: Uint8Array) {
  return encodeBase64ToBase64Url(encodeUint8ArrayToBase64(uint8Array));
}

function encodeStringToUint8Array(string: string) {
  return new TextEncoder().encode(string);
}

function encodeAnyValueToBase64Url(value: any) {
  return encodeBase64ToBase64Url(btoa(JSON.stringify(value)));
}

async function generateKey(key: string) {
  return crypto.subtle.importKey(
    'raw',
    encodeStringToUint8Array(key),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign', 'verify'],
  );
}

async function getJsonWebToken(key: CryptoKey, data: { sub: string }) {
  const header = encodeAnyValueToBase64Url({ alg: 'HS256', typ: 'JWT' });

  const payload = encodeAnyValueToBase64Url(data);

  const signature = encodeUint8ArrayToBase64Url(
    new Uint8Array(
      await crypto.subtle.sign(
        { name: 'HMAC' },
        key,
        encodeStringToUint8Array(`${header}.${payload}`),
      ),
    ),
  );
  return `${header}.${payload}.${signature}`;
}

const isCryptoSubtleLoaded = () => {
  let i = 0;
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (crypto && crypto.subtle) {
        clearInterval(interval);
        resolve(true);
      }
      if (i === 5) {
        clearInterval(interval);
        resolve(false);
      }
      i += 1;
    }, 100);
  });
};

async function generateJsonWebToken(subject: string) {
  const isCrypto = await isCryptoSubtleLoaded();
  if (!isCrypto) {
    throw new Error(
      `crypto.subtle is ${typeof crypto.subtle}. Maybe the iframe parent is not https.`,
    );
  }

  const data = { sub: subject };
  const publicKey = process.env.NEXT_PUBLIC_ONE_TRUST_PUBLIC_KEY;
  const key = await generateKey(publicKey);
  return getJsonWebToken(key, data);
}

export default generateJsonWebToken;
