import log from 'loglevel';
import { EventType } from '../../types/analytics';

declare const window: Window &
  typeof globalThis & {
    tC: any;
  };

export function logTagCommanderEvent(event: EventType) {
  if (!window.tC) {
    return;
  }

  try {
    switch (event.event) {
      case 'Solar Interest':
        window.tC.event.solar_interest(this, event);
        break;
      case 'Solar Activation':
        window.tC.event.activation_complete(this, event);
        break;
      case 'Solar Activation Form':
        window.tC.event.activation_start(this, event);
        break;
      case 'Calculation Shown':
        window.tC.event.calculation_shown(this, event);
        break;
      case 'Surface submitted':
        window.tC.event.surface_added(this, event);
        break;
      default:
        break;
    }
  } catch (error) {
    log.warn(
      `Error while logging event ${event.event} to Tag Commander:`,
      error,
    );
  }
}
