import { Otovo$Locale } from '@otovo/shared/types/otovoweb';

export enum CaptureEvent {
  INTEREST_CREATED = 'interest_created',
  INTEREST_ACTIVATED = 'interest_activated',
}

type Args = {
  eventName: CaptureEvent;
  locale: Otovo$Locale;
};

export async function capturePostHogEvent({ eventName, locale }: Args) {
  try {
    await fetch(`/${locale}/posthog/capture/?eventName=${eventName}`);
  } catch (_) {
    // it's ok
  }
}
