const {
  captureException,
  withScope,
  captureMessage,
  flush,
} = require('@sentry/nextjs');

const { getRequestId } = require('../requestIdService');

module.exports.logToSentry = (msg, extra = {}, level = 'error') => {
  withScope((scope) => {
    scope.setLevel(level);
    scope.setExtras({
      ...extra,
      requestId: getRequestId(),
    });
    captureMessage(msg);
    flush(2000);
  });
};

module.exports.logSentryError = (error, _extra) => {
  const extra =
    typeof _extra === 'string'
      ? {
          message: _extra,
        }
      : _extra;

  withScope((scope) => {
    scope.setLevel('error');
    scope.setExtras({
      ...extra,
      requestId: getRequestId(),
    });
    captureException(error);
    flush(2000);
  });
};
