const PROTOCOL_REGEXP = /.*:\/\//;
const PORT_REGEXP = /:\d+/;
const URL_PATH_SEPARATOR = '/';

export default function referrerParser(
  referrer?: string | null,
): null | string {
  if (!referrer) {
    return null;
  }

  return referrer
    .replace(PROTOCOL_REGEXP, '')
    .replace(PORT_REGEXP, '')
    .split(URL_PATH_SEPARATOR)[0];
}
