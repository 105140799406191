import { logSentryError } from './sentry';

import { cookieEnum, getCookieValue } from './cookieUtils';
import generateJsonWebToken from './generateJsonWebToken';

export async function getOneTrustInitialSnippet() {
  const { COOKIE_KEY } = cookieEnum;
  const id = getCookieValue(COOKIE_KEY);
  const isHttps =
    typeof window !== 'undefined' && window.location.protocol === 'https:';

  if (isHttps) {
    try {
      const jsonWebToken = await generateJsonWebToken(id);
      const oneTrustInitialSnippet = `
            var OneTrust = {
              dataSubjectParams: {
                  id: "${id}",
                  isAnonymous: false,
                  token : '${jsonWebToken}'
              }
            };`;
      return oneTrustInitialSnippet;
    } catch (error) {
      logSentryError(error, {
        description: `Error in getOneTrustInitialSnippet, could not generate json web token.
        The user will not be tracked cross domain.`,
      });
      return '';
    }
  }
  return null;
}
