/*
This file holds state for the <Head ... /> component to support base urls
based on the app (my page / Breeze / Storefront)
*/

let baseUrl;

export function setHeadBaseUrl(_baseUrl?: string | null) {
  baseUrl = _baseUrl ? _baseUrl.replace(/\/+$/, '') : _baseUrl;
}

export function getHeadBaseUrl(): string | null | undefined {
  return baseUrl;
}
