const messageDefaults = {
  // Event will be rejected if source is missing.

  // important: do not update this without also updating ACCEPTED_SOURCE in
  // breeze/public/js/search.js:3
  source: 'otovo',
};

type HeightEvent = {
  height: string;
  type: 'HEIGHT_CHANGED';
  debug: boolean;
};

type UserEvent = {
  action: string;
  type: 'USER_EVENT';
  debug: boolean;
};

export function broadcastEvent(eventDetails: HeightEvent | UserEvent) {
  window.parent.postMessage(
    {
      ...messageDefaults,
      ...eventDetails,
    },
    '*',
  );
}

export function broadcastHeight(height: number, debug: boolean) {
  broadcastEvent({
    type: 'HEIGHT_CHANGED',
    height: `${height}px`,
    debug,
  });
}
